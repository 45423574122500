export const signInFormFactors = [
  {
    name: "Desktop",
    markdown: `
1. Visit Tower Hunt and click on the **Sign in** link in the upper-right corner, or visit [towerhunt.com/sign-in](/sign-in).
2. Enter your email address and password and click **Sign in**.`,
  },
  {
    name: "Mobile",
    markdown: `
1. Visit Tower Hunt and tap on the {% inlineAppIcon iconName="bars" /%}**Menu button** in the upper-right corner.
2. Tap **Sign in** in the menu that appears.
3. Enter your email address and password and tap **Sign in**.`,
  },
];

export const signInToTowerHunt = {
  order: 7,
  name: "Sign in to Tower Hunt",
  keywords: "log forgot password",
  subcategory: "Manage your account",
  markdown: `# Sign in to Tower Hunt
  Tower Hunt accounts are tied to email addresses. Read on to learn how to sign in to Tower Hunt with your email address.

  ## Sign in

  {% tabbedContainer tabs=$signInFormFactors /%}

  ## Troubleshoot sign-in issues

  If you're unable to sign in to Tower Hunt, read on to learn how to troubleshoot some of the most common issues.

  ### You forgot your password

  Learn how to {% inlineRouterLink articleId="reset-your-password" %}set a new password{% /inlineRouterLink %} for your account.

  ### You're not receiving emails

  - **Check that you created your Tower Hunt account with the correct email.** If you have different personal and work email addresses, visit [towerhunt.com/sign-in](/sign-in) and enter your email address to make sure you're using the right one.
  - **Check your spam folder and talk to your IT team.** If emails from Tower Hunt aren't getting delivered to your inbox or spam folder, ask your IT team to allow emails from @towerhunt.com.

  {% callout type="info" %}
  **Still having trouble?** [Let us know](mailto:team@towerhunt.com) and someone from our team can help.
  {% /callout %}`,
};
